import React from 'react';
import { get } from 'lodash';
import { APP_AI_ALERTS } from '@subApps/navApp/hooks/useData/usePulse5Features/featureNames';
import { usePulse5Features, useAIAlertsSummary } from '@subApps/navApp/hooks/useData';
import { LoadingIcon } from '@subApps/common/components/Icons';
import { useGetMergedMetrics } from '@subApps/navApp/hooks/useData/useFetchMetricDefinition/useGetMergedMetrics';
import { sortAlertsSummary } from '@subApps/navApp/utils';
import { OverlayWrapper, MsgWrapper, OverlayTitle, AlertsWrapper } from './styled';
import AIAlertItem from './AIAlertItem';

const EMPTY_MASSAGE = 'There is no alerts.';
const MAX_ITEM_COUNT = 3;

const APPAlertBellOverlay = () => {
  const { data: features } = usePulse5Features();
  const isAppAIAlertEnabled = get(features, APP_AI_ALERTS, false);

  const { data = [], loading: aiAlertLoading } = useAIAlertsSummary({
    type: 'app',
    limit: MAX_ITEM_COUNT,
  });
  const { result: metricsMeta, loading: metricMetaLoading } = useGetMergedMetrics({ type: 'app' });

  if (!isAppAIAlertEnabled) return null;

  const isEmptyAIAlert = !aiAlertLoading && !data.length;
  const sortedAlertsSummary = sortAlertsSummary(data);

  return (
    <OverlayWrapper>
      <OverlayTitle>
        <span>Recent AI Alerts</span>
        {aiAlertLoading && <LoadingIcon />}
      </OverlayTitle>
      {isEmptyAIAlert && <MsgWrapper>{EMPTY_MASSAGE}</MsgWrapper>}
      {metricMetaLoading ? <LoadingIcon style={{ margin: '8px auto', display: 'block' }} /> : null}
      {!metricMetaLoading && !aiAlertLoading && !isEmptyAIAlert && (
        <AlertsWrapper>
          {sortedAlertsSummary.slice(0, 3).map(event => (
            <AIAlertItem key={event.alertId} event={event} metricsMeta={metricsMeta} isAPP />
          ))}
        </AlertsWrapper>
      )}
    </OverlayWrapper>
  );
};

export default React.memo(APPAlertBellOverlay);
