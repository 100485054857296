import React from 'react';
import { SWRConfig } from 'swr';
import { theme as convivaTheme } from '@conviva/vision-antd';
import { ConfigProvider } from 'antd';
import { createRoot } from 'react-dom/client';
import { setupDarkAlphaTheme } from '@store/theme';
import { Header, SubMenu, Navigation } from './components';
import { useInitProduct, useProduct } from './hooks/useGlobal';
import { setProductsByPathname } from './hooks/useGlobal/useInitProduct';
import { useAppLoading } from './hooks/useData';
import useSensor, { trackEvent } from './hooks/useGlobal/useSensor';
import HelpSideBar from './components/HelpSideBar/HelpSideBar';
import CheckActiveCustomer from './components/common/CheckActiveCustomer';

const App = ({ setGlobalState }) => {
  const loading = useAppLoading(setGlobalState);
  const { onStateChange: setProduct, data: product } = useProduct();

  useInitProduct();

  useSensor();

  React.useEffect(() => {
    const callback = () => {
      // set product on click of browser back and forward button
      if (window.event?.type === 'popstate') {
        setProductsByPathname(window.location.pathname, setProduct);
      }
    };
    window.addEventListener('popstate', callback);
    return () => {
      window.removeEventListener('popstate', callback);
    };
  }, []);

  // setup and cleanup dark alpha theme
  React.useEffect(() => {
    setupDarkAlphaTheme(product);
  }, [product]);

  if (loading) {
    return null;
  }

  return (
    <SWRConfig
      value={{
        onErrorRetry: err => ![404, 401, 403].includes(err.status),
      }}
    >
      <ConfigProvider prefixCls="apphub" theme={convivaTheme.vision}>
        <Header />
        <Navigation />
        <SubMenu />
        <HelpSideBar />
        <CheckActiveCustomer />
      </ConfigProvider>
    </SWRConfig>
  );
};

const wrapperId = '__nav-app-root__';
let root;

const render = props => {
  const { container } = props;

  const wrapper: HTMLDivElement =
    container.querySelector(`#${wrapperId}`) || document.createElement('div');
  wrapper.id = wrapperId;
  container.appendChild(wrapper);

  root = createRoot(wrapper);
  root.render(<App {...props} />);
};

export async function bootstrap() {
  window.conviva = {};
}

export async function mount(props) {
  trackEvent({
    name: 'app_mounted',
    data: {
      app: 'AppHub',
      loadTime: Date.now() - performance.timing.fetchStart,
    },
  });

  Object.defineProperty(window.conviva, 'microProps', {
    value: props,
    writable: false,
  });
  render(props);
}

export async function unmount() {
  if (root) {
    root.unmount();
  }
}

if (!window.__POWERED_BY_QIANKUN__) {
  render({});
}
