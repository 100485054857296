import { get } from 'lodash';
import { P4Nav } from '@subApps/navApp/hooks/useData/useNav/typed';
import User from 'subApps/navApp/hooks/useData/useUser/user.model';
import { PRODUCT_URL_PREFIX, PRODUCTS } from '@constants/products';
import { isSIOnly } from '@utils/cookie';
import * as FF from '@subApps/navApp/hooks/useData/usePulse5Features/featureNames';

export enum MENU_TYPES {
  MY_PROFILE = 'My Profile',
  ACCOUNT_OVERVIEW = 'Account Overview',
  FEATURE_SETTINGS = 'Feature Settings',
}

export enum MY_PROFILE_MENUS {
  PERSONAL_INFORMATION = 'Personal Information',
  CHANGE_PASSWORD = 'Change Password',
  APPLICATIONS = 'Applications',
  ACCOUNT_ASSIGNMENT = 'Account Assignment',
}

export enum ACCOUNT_OVERVIEW_MENUS {
  ACCOUNT_DETAILS = 'Account Details',
  USAGE = 'Usage Dashboard',
}

export enum FEATURE_SETTINGS_MENUS {
  SESSION_SOURCE_DATA = 'Session Source Data',
  SESSION_TAGS = 'Session Tags',
  EXECUTIVE_EMAIL = 'Executive Email v1.0',
  PAGERDUTY = 'PagerDuty',
  ERROR_CONFIG = 'Business Error Configuration',
  CONVIVA_EXECUTIVE_REPORT = 'Executive Email v2.0',
  API_MANAGEMENT = 'API Management',
  USER_MANAGEMENT = 'User Management',
  FILTER_MANAGEMENT = 'Filter Management',
  DIMENSION_MANAGEMENT = 'Dimension Management',
  EMAIL_SUBSCRIPTIONS = 'Email Subscriptions',
  AD_ACCOUNT_SETTINGS = 'Ad Account Settings',
  SERVICE_INTEGRATIONS = 'Service Integrations',
  WEBHOOKS = 'Webhooks',
}

// get display name by converting enum to object
// the enum value is used as display name
export const SUB_MENU_DISPLAY_NAMES = [
  MENU_TYPES,
  MY_PROFILE_MENUS,
  ACCOUNT_OVERVIEW_MENUS,
  FEATURE_SETTINGS_MENUS,
].reduce((acc, menu) => {
  Object.values(menu).reduce((maps, v) => {
    maps[v] = v;
    return maps;
  }, acc);
  return acc;
}, {});

export interface SubMenu {
  id: MY_PROFILE_MENUS | ACCOUNT_OVERVIEW_MENUS | FEATURE_SETTINGS_MENUS;
  parentName?: string;
  displayName?: string;
  url: ((product: PRODUCTS) => string) | string;
  valid: (input: { nav?: P4Nav; features?: any; product?: PRODUCTS; user?: User }) => boolean;
}

export const SUB_MENUS: {
  [key in MENU_TYPES]: SubMenu[];
} = {
  [MENU_TYPES.MY_PROFILE]: [
    {
      id: MY_PROFILE_MENUS.PERSONAL_INFORMATION,
      url: '/app/settings/personal_info',
      valid: () => true,
    },
    {
      id: MY_PROFILE_MENUS.CHANGE_PASSWORD,
      url: '/app/pulse/settings/change_password',
      valid: ({ nav }) => nav!.profile.some(m => m.name === 'Change Password'),
    },
    {
      id: MY_PROFILE_MENUS.APPLICATIONS,
      url: '/app/profile/applications',
      valid: () => !isSIOnly(),
    },
    {
      id: MY_PROFILE_MENUS.ACCOUNT_ASSIGNMENT,
      url: '/app/pulse/settings/account_assignment',
      valid: ({ nav }) => nav!.profile.some(m => m.name === 'Account Assignment'),
    },
  ],
  [MENU_TYPES.ACCOUNT_OVERVIEW]: [
    {
      id: ACCOUNT_OVERVIEW_MENUS.ACCOUNT_DETAILS,
      url: (product: PRODUCTS) => {
        if (product === PRODUCTS.APP) {
          return '/app/app-insights/account/details';
        }
        return '/app/pulse/package/';
      },
      valid: ({ nav, features, product }) => {
        if (product === PRODUCTS.APP) {
          return get(features, FF.ECO_TRENDS, false);
        }
        return nav!.ei_setting.some(m => m.name === 'Account Details');
      },
    },

    {
      // remove this menu after GA release.
      // if CONTRACT_LEVEL_USAGE_DASHBOARD is true show pulse5 dashboard
      // else show pulse4 dashboard
      id: ACCOUNT_OVERVIEW_MENUS.USAGE,
      url: (product: PRODUCTS) =>
        product === PRODUCTS.EI ? '/app/pulse/reports/25' : '/app/pulse/reports/60',
      valid: ({ nav, features, product }) => {
        const baseValid = nav!.ei_setting.some(m => m.name === ACCOUNT_OVERVIEW_MENUS.USAGE);
        // Add permission control to ADS only.
        if (product === PRODUCTS.AI) {
          // Equal to or greater than 5 will display the Usage Dashboard,
          // while less than 5 will not show the Usage Dashboard.
          const adsValid = features[FF.AD_INSIGHTS] >= 5;
          return baseValid && adsValid;
        }
        return baseValid;
      },
    },
  ],

  [MENU_TYPES.FEATURE_SETTINGS]: [
    {
      id: FEATURE_SETTINGS_MENUS.USER_MANAGEMENT,
      url: '/app/pulse/users/',
      valid: ({ nav }) => nav!.ei_setting.some(m => m.name === 'Users'),
    },
    {
      id: FEATURE_SETTINGS_MENUS.API_MANAGEMENT,
      url: '/app/manage/apps/list',
      valid: ({ nav, product }) =>
        nav!.ei_setting.some(m => m.name === 'API Management') && product !== PRODUCTS.APP,
    },
    {
      id: FEATURE_SETTINGS_MENUS.SESSION_SOURCE_DATA,
      url: '/app/pulse/ssd/',
      valid: ({ nav, product }) =>
        nav!.ei_setting.some(m => m.name === 'Session Source Data') && product !== PRODUCTS.APP,
    },
    {
      id: FEATURE_SETTINGS_MENUS.SESSION_TAGS,
      url: '/app/pulse/subscribers/tags',
      valid: ({ nav, product }) =>
        nav!.ei_setting.some(m => m.name === 'Session Tags') && product !== PRODUCTS.APP,
    },
    {
      id: FEATURE_SETTINGS_MENUS.EXECUTIVE_EMAIL,
      url: '/app/pulse/exec_email/',
      valid: ({ nav, product }) =>
        nav!.ei_setting.some(m => m.name === 'Executive Email v1.0') && product !== PRODUCTS.APP,
    },
    {
      id: FEATURE_SETTINGS_MENUS.CONVIVA_EXECUTIVE_REPORT,
      url: PRODUCT_URL_PREFIX[PRODUCTS.execReport],
      valid: ({ nav, product }) =>
        nav!.ei_setting.some(m => m.name === 'Executive Email v2.0') && product !== PRODUCTS.APP,
    },
    {
      id: FEATURE_SETTINGS_MENUS.PAGERDUTY,
      url: (product: PRODUCTS) => {
        let manageUrl = '/app/pulse/pagerduty/';
        if (product === PRODUCTS.APP) {
          manageUrl = '/app/pulse/pagerduty/app/';
        }
        return manageUrl;
      },
      valid: ({ nav, product }) => {
        if (product === PRODUCTS.EI) {
          return nav!.ei_setting.some(m => m.name === 'PagerDuty');
        }

        if (product === PRODUCTS.APP) {
          return nav!.app_setting.some(m => m.name === 'PagerDuty');
        }

        return false;
      },
    },
    {
      id: FEATURE_SETTINGS_MENUS.ERROR_CONFIG,
      url: '/app/experience-insights/dashboard/error-config',
      valid: ({ user, product }) => product === PRODUCTS.EI && user!.isAdmin(),
    },
    {
      id: FEATURE_SETTINGS_MENUS.AD_ACCOUNT_SETTINGS,
      url: '/app/pulse/settings/adi/parameters',
      valid: ({ nav, product }) =>
        product === PRODUCTS.AI && nav!.ai_setting.some(m => m.name === 'Ad Account Settings'),
    },
    {
      id: FEATURE_SETTINGS_MENUS.SERVICE_INTEGRATIONS,
      url: (product: PRODUCTS) =>
        product === PRODUCTS.APP
          ? '/app/app-insights/dashboard/service-integrations'
          : '/app/experience-insights/dashboard/service-integrations',
      valid: ({ product, features }) => {
        const serviceIntegrationEnabled = features[FF.SERVICE_INTEGRATION_ENABLED];
        if (product === PRODUCTS.EI) return features[FF.AI_ALERTS];
        // Not available currently
        if (product === PRODUCTS.APP)
          return features[FF.AI_ALERTS] && features[FF.APP_AI_ALERTS] && serviceIntegrationEnabled;
      },
    },
    {
      id: FEATURE_SETTINGS_MENUS.WEBHOOKS,
      url: (product: PRODUCTS) => {
        let webhookUrl = '/app/experience-insights/settings/webhooks';
        if (product === PRODUCTS.APP) {
          webhookUrl = '/app/app-insights/settings/webhooks';
        } else if (product === PRODUCTS.AI) {
          webhookUrl = '/app/ad-insights/settings/webhooks';
        }
        return webhookUrl;
      },
      valid: ({ features, product }) => {
        if (product === PRODUCTS.EI) {
          return features[FF.AI_ALERTS];
        }

        if (product === PRODUCTS.APP) {
          // pulse4 had only ai_alerts FF check, addin app-ai-alerts FF check as well
          // as it doesn't make sense to not show alerts dasboard but show email subscriptions drawer
          return features[FF.APP_AI_ALERTS] && features[FF.AI_ALERTS];
        }

        if (product === PRODUCTS.AI) {
          return features[FF.AD_AI_ALERTS];
        }

        return false;
      },
    },
  ],
};

// get parentName and displayName if not provided
Object.entries(SUB_MENUS).forEach(([parentMenu, menus]) => {
  menus.forEach(m => {
    if (!m.parentName) {
      m.parentName = SUB_MENU_DISPLAY_NAMES[parentMenu];
    }
    if (!m.displayName) {
      m.displayName = SUB_MENU_DISPLAY_NAMES[m.id];
    }
  });
});
