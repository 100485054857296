export enum PRODUCTS {
  EI = 'EI',
  AI = 'AI',
  VDASH = 'VDASH',
  Tool = 'Tool',
  AUI = 'AUI',
  precision = 'precision',
  community = 'community',
  touchstone = 'touchstone',
  touchstone2_0 = 'touchstone2_0',
  applicationManager = 'applicationManager',
  appManager = 'appManager',
  ecoAppManager = 'ecoAppManager',
  metadataManager = 'MetadataManager',
  mdsPortal = 'mdsPortal',
  admin = 'Admin',
  pulse4 = 'Pulse',
  profile = 'Profile',
  execReport = 'execReport',
  VI = 'VI',
  CUDA_SI = 'CUDA_SI',
  SI = 'SI',
  CI = 'CI',
  ADVI = 'ADVI',
  daas = 'daas',
  otherDashboard = 'otherDashboard',
  APP = 'APP',
  integration = 'integration',
  deviceValidation = 'device-validation',
  dataManagement = 'dataManagement',
  ODP = 'ODP',
  metricBuilder = 'metricBuilder',
  ECO_SERVER = 'ECO_SERVER',
  ACTIVATION = 'ACTIVATION',
  ACTIVATION_PREVIEW = 'ACTIVATION_PREVIEW',
}

export const PRODUCT_DISPLAY_NAMES_NEW_NAV = {
  [PRODUCTS.EI]: 'VIDEO',
  [PRODUCTS.AI]: 'ADS',
  [PRODUCTS.APP]: 'ECO',
  [PRODUCTS.VI]: 'AUDIENCE',
  [PRODUCTS.SI]: 'SOCIAL',
  [PRODUCTS.VDASH]: 'CUSTOM DASHBOARDS',
  [PRODUCTS.Tool]: 'INTEGRATION TOOLS',
  [PRODUCTS.daas]: 'APIs & DATA FEEDS',
  [PRODUCTS.dataManagement]: 'ODP',
  [PRODUCTS.ODP]: 'ODP',
  [PRODUCTS.metricBuilder]: 'ODP',
  [PRODUCTS.ECO_SERVER]: 'ECO SERVER',
  [PRODUCTS.ACTIVATION]: 'ACTIVATION',
  [PRODUCTS.ACTIVATION_PREVIEW]: 'ACTIVATION PREVIEW',
};

export const PRODUCT_DISPLAY_NAMES = {
  [PRODUCTS.EI]: 'Experience Insights',
  [PRODUCTS.AI]: 'Ad Experience',
  [PRODUCTS.APP]: 'ECO',
  [PRODUCTS.ACTIVATION]: 'ACTIVATION',
  [PRODUCTS.VDASH]: 'Custom Dashboard',
  [PRODUCTS.AUI]: 'Audience Insights',
  [PRODUCTS.precision]: 'Precision',
  [PRODUCTS.community]: 'Learning Center',
  [PRODUCTS.touchstone]: 'Touchstone 1.0',
  [PRODUCTS.touchstone2_0]: 'Touchstone 2.0',
  [PRODUCTS.applicationManager]: 'Application Manager',
  [PRODUCTS.appManager]: 'Video Integration',
  [PRODUCTS.ecoAppManager]: 'ECO Integration',
  [PRODUCTS.admin]: 'Admin',
  [PRODUCTS.pulse4]: 'Pulse',
  [PRODUCTS.profile]: 'Profile',
  [PRODUCTS.metadataManager]: 'Metadata Service',
  [PRODUCTS.mdsPortal]: 'Metadata Service Portal',
  [PRODUCTS.VI]: 'Viewer Insights',
  [PRODUCTS.SI]: 'Social Insights',
  [PRODUCTS.CI]: 'Viewer Insights',
  [PRODUCTS.ADVI]: 'Advertising Insights (Alpha) New',
  [PRODUCTS.daas]: 'Conviva Connect',
  [PRODUCTS.otherDashboard]: 'Other Dashboards',
  [PRODUCTS.dataManagement]: 'ODP',
  [PRODUCTS.ODP]: 'ODP',
  [PRODUCTS.metricBuilder]: 'ODP',
  [PRODUCTS.ECO_SERVER]: 'ECO SERVER',
  [PRODUCTS.ACTIVATION_PREVIEW]: 'ACTIVATION PREVIEW',
};

export const PRODUCT_DISPLAY_NAMES_TOOLTIP = {
  [PRODUCTS.APP]: 'Experience Centric Observability',
};

export const PRODUCT_URL_PREFIX = {
  [PRODUCTS.EI]: '/app/experience-insights',
  [PRODUCTS.AI]: '/app/ad-insights',
  [PRODUCTS.APP]: '/app/app-insights',
  [PRODUCTS.integration]: '/app/integration',
  [PRODUCTS.VDASH]: '/app/custom-dashboards',
  [PRODUCTS.AUI]: '/app/pulse/audience_insights',
  [PRODUCTS.precision]: '/app/precision',
  [PRODUCTS.admin]: '/app/manage',
  [PRODUCTS.pulse4]: '/app/pulse',
  [PRODUCTS.profile]: '/app/profile',
  [PRODUCTS.metadataManager]: '/app/mds',
  [PRODUCTS.mdsPortal]: '/app/mds-portal',
  [PRODUCTS.execReport]: '/app/executive_report',
  [PRODUCTS.VI]: '/app/viewer-insights/dashboard',
  [PRODUCTS.CUDA_SI]: '/app/viewer-insights/social/dashboard',
  [PRODUCTS.SI]: '/app/viewer-insights/social',
  [PRODUCTS.CI]: '/app/viewer-insights',
  [PRODUCTS.ADVI]: '/app/advertising_new',
  [PRODUCTS.daas]: '/app/conviva-connect',
  [PRODUCTS.appManager]: '/app/appmanager',
  [PRODUCTS.ecoAppManager]: '/app/appmanager/ecoIntegration',
  [PRODUCTS.deviceValidation]: '/app/pulse/device_validation',
  [PRODUCTS.dataManagement]: '/app/odp/data-management',
  [PRODUCTS.ODP]: '/app/odp/dashboard',
  [PRODUCTS.metricBuilder]: '/app/odp/metric-builder',
  [PRODUCTS.ECO_SERVER]: '/app/eco-server',
  [PRODUCTS.ACTIVATION]: '/app/activation',
  [PRODUCTS.ACTIVATION_PREVIEW]: '/app/activation-preview',
};

export default PRODUCTS;
