import React from 'react';
import { createPortal } from 'react-dom';
import { isFunction } from 'lodash';
import { PRODUCTS } from '@constants/products';
import { useProduct } from '@subApps/navApp/hooks/useGlobal';
import { useNav, usePulse5Features, useUser } from '@subApps/navApp/hooks/useData';
import { SUB_MENUS } from '@constants/subMenu';
import { useSubMenu } from '@subApps/navApp/hooks/common';
import { urlMatch } from '@utils/url';
import { Wrapper, MenuItem } from './styled';

const Menus = () => {
  const [subMenu] = useSubMenu();
  const { data: navData } = useNav();
  const { data: product } = useProduct();
  const { data: features } = usePulse5Features();
  const { data: user } = useUser();
  if (subMenu === null) {
    return null;
  }
  const [menuType] = subMenu;
  const $contentEl = document.querySelector('.conviva-content') || document.body;

  return createPortal(
    <Wrapper className="apphub-sub-menu-wrapper">
      {SUB_MENUS[menuType].map(menu => {
        if (menu.valid({ nav: navData, features, product, user })) {
          const actualUrl = isFunction(menu.url) ? menu.url(product as PRODUCTS) : menu.url;
          return (
            <MenuItem
              key={menu.id}
              name={menu.displayName!}
              isActive={urlMatch(window.location.pathname, actualUrl)}
              url={actualUrl}
              showIcon={false}
            />
          );
        }
        return null;
      })}
    </Wrapper>,
    $contentEl,
  );
};

export default Menus;
